import React from 'react';
import PropTypes from 'prop-types';
import SiteLowerFooterLogo from './SiteLowerFooterLogo/SiteLowerFooterLogo';
import SiteLowerFooterMenu from './SiteLowerFooterMenu/SiteLowerFooterMenu';
import SiteLowerFooterCopyright from './SiteLowerFooterCopyright/SiteLowerFooterCopyright';
import './SiteLowerFooter.scss';
import { useRootSelector } from '../../../../../store/hooks';
import { selectTenantConfig } from '../../../../../store/features/tenantConfig';

const defaultLowerFooterMenu = [
  {
    title: 'event.home-page',
    href: '/',
    parent: true,
  },
  {
    title: 'event.contact',
    href: '/contact/',
  },
  {
    title: 'event.privacy-centre',
    href: 'https://informa.com/privacy-policy/',
    parent: true,
    target: '_blank',
  },
  {
    title: 'event.terms-of-use',
    href: '/terms-of-use',
    parent: true,
    target: '_blank',
  },
  {
    title: 'event.terms-and-conditions',
    href: '/terms-and-conditions',
  },
  {
    title: 'event.ccpa',
    href: 'https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c1f53e84-9f05-4169-a854-85052b63c50b/5f26b553-52cc-4973-a761-295d5634a6b6.html',
  },
];

function getLowerFooterConfig(tenantId) {
  switch (tenantId) {
    case 'FANEXPOHQ':
    case 'ONEOFAKINDSHOW':
    case 'INTERIORDESIGNSHOW':
    case 'ARTTORONTO':
    case 'THEARTISTPROJECT':
    case 'MEFCC':
      return {
        logo: {
          link: 'https://www.informa.com/',
          url: '/images/btyb_informa_connect_logo_white.png',
          alt: 'Informa Connect',
        },
        menu: [
          {
            title: 'Privacy Policy',
            href: 'https://informa.com/generic-content/privacy-policy/',
          },
          {
            title: 'Cookie Policy',
            href: 'https://informa.com/generic-content/cookie-policy/',
          },
          {
            title: 'Terms of Use',
            href: 'https://informa.com/generic-content/terms--conditions/',
          },
        ],
      };

    case 'NETWORKXEVENT':
      return {
        logo: {
          alt: 'Informa Connect',
          url: '/images/informa-tech-logo-footer@2x.png',
        },
        menu: defaultLowerFooterMenu,
      };

    default:
      return {
        logo: {
          link: '/',
          url: '/images/btyb_informa_connect_logo_white.png',
          alt: 'Informa Connect',
        },
        menu: defaultLowerFooterMenu,
      };
  }
}

/**
 * WHITE LABEL
 */
export default function SiteLowerFooter({ bgColor }) {
  const { id: tenantId } = useRootSelector(selectTenantConfig);
  const lowerFooter = getLowerFooterConfig(tenantId);

  return (
    <div
      className="footer-lower"
      style={{ backgroundColor: bgColor }}
      data-testid="lower-footer"
    >
      <div className="container">
        <div className="footer-lower__wrapper">
          <div className="footer-lower__logo">
            <SiteLowerFooterLogo lowerFooter={lowerFooter} />
          </div>
          <div className="footer-lower__menu">
            <SiteLowerFooterMenu lowerFooter={lowerFooter} />
          </div>
        </div>
        <SiteLowerFooterCopyright />
      </div>
    </div>
  );
}

SiteLowerFooter.propTypes = {
  bgColor: PropTypes.string,
};
