import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import type {
  GenericState,
  UniqueIdentifier,
  UploadedImage,
} from '../../../../types';
import type { RootState } from '../../../store';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../generic';
import { ArticleTurtl } from '../../siteArticles/siteArticleDetails';

interface Sponsor {
  id: UniqueIdentifier;
  name: string;
  logo: string;
  link: string;
}

interface Author {
  id: UniqueIdentifier;
  forename: string;
  surname: string;
  fullName: string;
}

export interface ArticleHomeRelatedArticle {
  articleId: UniqueIdentifier;
  title: string;
  imageUrl: string;
  primaryTopic: string;
  articlePath: string;
}

export interface ArticleHomeRelatedEvent {
  eventId: UniqueIdentifier;
  details: {
    name: string;
    path: string;
    date: {
      startDate: string;
      endDate: string;
      formattedDate: string;
    };
    address: {
      name: string;
      city: string;
      address: string;
      country: string;
      searchCity: string;
    };
  };
  backgroundImage?: UploadedImage;
  brand: {
    colours: {
      accent: string;
      primary: string;
      secondary: string;
    };
  };
  timeAsText: string;
  deliveryType: string;
}

export interface ArticleHome {
  data: {
    title: string;
    dateModified: string;
    datePublished: string;
    articlePath: string;
    communityPath: string;
    articleId: string;
    summary?: string;
    imageUrl?: string;
    mailToLink?: string;
    content?: string;
    contentType?: string;
    primaryTopic?: string;
    uploadedPdfUrls?: string[];
    relatedTopics?: string[];
    l1Topics?: string[];
    l2Topics?: string[];
    tags?: string[];
    showMoreRelatedArticlesLink?: boolean;
    showMoreEventsLink?: boolean;
    sponsoredContent?: boolean;
    advertisingEnabled?: boolean;
    author?: Author;
    sponsor?: Sponsor;
    relatedArticles?: ArticleHomeRelatedArticle[];
    featuredEvent?: ArticleHomeRelatedEvent;
    turtl?: ArticleTurtl;
  };
  meta: {
    title: string;
    gated: boolean;
  };
}

export interface ArticleHomeParams {
  articleId: UniqueIdentifier;
}

export const fetchArticleHome = createGenericAsyncThunk<
  ArticleHome,
  ArticleHomeParams
>(
  'articleHome/fetchArticleHome',
  async ({ articleId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/articles/${articleId}/home`,
      {
        headers: {
          'Accept-Language': extra.i18n.language,
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: ArticleHome = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const articleHomeSlice = createGenericSlice({
  name: 'articleHome',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<ArticleHome>,
  reducers: {
    setGated(state, { payload: gated }: PayloadAction<boolean>) {
      state.data.meta.gated = gated;
    },
    removeBody(state) {
      const {
        articleId,
        title,
        contentType,
        author,
        relatedTopics,
        communityPath,
        dateModified,
        datePublished,
      } = state.data.data;

      state.data.data = {
        articleId,
        title,
        contentType,
        author,
        relatedTopics,
        communityPath,
        dateModified,
        datePublished,
      } as ArticleHome['data'];
    },
  },
  extraReducers: (builder) => {
    createGenericBuilderCases<ArticleHome, ArticleHomeParams>(
      builder,
      fetchArticleHome,
    );
  },
});

export const selectArticleHome = createSelector(
  [(state: RootState) => state.article.home.data],
  (data) => data,
);

export const selectArticleHomeGated = createSelector(
  [(state: RootState) => state.article.home.data.meta.gated],
  (gated) => gated,
);

export const { start, fulfilled, rejected, setGated, removeBody } =
  articleHomeSlice.actions;

export default articleHomeSlice.reducer;
